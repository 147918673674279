<template>
  <div class="home">
    <!-- 轮播图 -->
    <div class="img-con">
      <swiper v-if="bannerList && bannerList.length" :list="bannerList" />
    </div>
    <!-- 按钮模块 -->
    <Navbtn></Navbtn>

    <div class="text-con">最新动态</div>
    <div class="list-con">
      <load-list :interFace="interFace">
        <template v-slot:list="props">
          <div
            v-for="(item, index) in props.list"
            :key="index"
            class="list"
            @click="toArticleDetail(item.id)"
          >
            <!-- 缩略图 -->
            <div class="img-con-2">
              <img :src="$IMG_PATH +item.img" alt />
            </div>
            <!-- 详细信息 -->
            <div class="right">
              <div class="right-item-up">
                {{
                  item.title
                }}
              </div>
              <div class="time-look">
                <span class="span-control-2">{{ item.add_time }}</span>
                <div>
                  <!-- <img src="@/assets/images/looks.png" alt="" /> -->
                  <span class="mr-5 iconfont icon-check-line"></span>
                  <span class="span-control-2">{{ item.click_count }}</span>
                </div>
              </div>
            </div>
          </div>
        </template>
      </load-list>
    </div>
  </div>
</template>

<script>
import Swiper from "@/components/Swiper";
import Navbtn from "../components/navBtn/navBtn3";
import {getBanner,getInform } from "../api";
import LoadList from "@/components/SearchLoadList";
export default {
  name: "servePerson",
  components: {
    Swiper,
    Navbtn,
    LoadList,
  },
  data() {
    return {
      interFace: {
        api: getInform,
        params: {
          cid: 61,
        },
      },
      bannerList:[],
    };
  },
  created() {
    this.init()
  },
  methods: {
    toArticleDetail(id) {
      this.$router.push({
        name: "articleDetail",
        params: {
          id: id,
        },
      });
    },
    init() {
      let params = {
        position: 12001,
      };
      getBanner(params).then((res) => {
        for (const i in res.data) {
          this.bannerList.push(this.$IMG_PATH + res.data[i].image);
        }
      });
    },
  },

};
</script>

<style scoped lang="less">
.home {
  width: 100%;
  padding: 0.24rem 0.3rem;
  overflow: hidden;
  .img-con {
    width: 100%;
    height: 2.8rem;
    overflow: hidden;
    margin-bottom: 0.24rem;
    /deep/.swiper {
      width: 100%;
      height: 2.8rem !important;
      overflow: hidden;
    }
    /deep/ img {
      width: 100%;
      height: 2.8rem !important;
      display: block;
      border-radius: 0.08rem;
    }
  }
  .notice-swipe {
    height: 0.4rem;
    line-height: 0.4rem;
  }
  /deep/ .van-icon {
    color: #ff0000 !important;
  }
  .img-con-5 {
    width: 6.9rem;
    height: 1.5rem;
    margin: 0.3rem auto;
  }
  .text-con{
      color: #3A3A3A;
      font-size: 0.36rem;
      margin: 0.29rem 0 0 0.28rem;
  }
  .list-con {
    .list {
      width: 6.9rem;
      height: 2rem;
      margin: 0 auto;
      background: #fff;
      border-radius: 8px;
      font-size: 0.32rem;
      color: #666666;
      margin-top: 0.2rem;
      padding: 0.25rem 0.25rem 0.25rem;
      display: flex;
      .img-con-2 {
        width: 3.4rem;
        height: 1.5rem !important;
        img {
          width: 100%;
          height: 100%;
          border-radius: 0.08rem;
        }
      }
      .right {
        width: 100%;
        font-size: 0.28rem;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        color: #666666;
        margin-left: 0.3rem;
        .right-item-up {
          height: 1rem;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          font-size: 0.32rem;
          color: #333333;
          line-height: 0.5rem;
          font-weight: 500;
          letter-spacing: 0.02rem;
        }
        .time-look {
          display: flex;
          justify-content: space-between;
          align-items: center;
          >div{
            display: flex;
            align-items: center;
            line-height: 120%;
            .mr-5{
              margin-right: 0.05rem;
            }
            .span-control-2 {
            color: #999999;
            font-size: 0.24rem;
            font-weight: 500;
            text-align: right;
            align-self: flex-end;
          }
          }
          
        }
      }
    }
  }
}
</style>