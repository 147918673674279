<template>
  <div class="nav-btn">
    <div class="item-btn" v-for="(item,index) in btnList" :key="index" @click="toList(item.cid)">
      <img :src="item.img" alt="" /> {{item.name}}
    </div>
    <!-- <div class="item-btn">
      <img src="@/assets/images/2.png" alt="" /> 扫黑除恶
    </div>
    <div class="item-btn">
      <img src="@/assets/images/3.png" alt="" /> 禁毒宣传
    </div>
    <div class="item-btn">
      <img src="@/assets/images/4.png" alt="" /> 便民服务
    </div> -->
  </div>
</template>

<script>
export default {
  name: "navBtn",
  data() {
    return {
        btnList:[
            {name:'民生动态',img:require('@/assets/images/6.png'),path:'/dynamic',cid:61},
            {name:'办事指南',img:require('@/assets/images/7.png'),path:'/dynamic',cid:62},
            {name:'政策文件',img:require('@/assets/images/8.png'),path:'/dynamic',cid:63},
        ]
    };
  },
  created() {},
  methods: {
      toList(cid){
          this.$router.push({
            name:'dynamic',
            params:{
              cid:cid
            }
          })
      }
  },
};
</script>

<style scoped lang="less">
.nav-btn {
  width: 6.9rem;
  height: 2.1rem;
  padding: 0 0.39rem;
  margin-top: 0.3rem;
  background-color: #fff;
  display: flex;
  justify-content: space-around;
  border-radius: 0.08rem;
  .item-btn {
    margin: auto 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #424242;
    font-size: 0.26rem;
    img {
      width: 0.85rem;
      height: 0.85rem;
      margin-bottom: 0.16rem;
    }
  }
}
</style>