<template>
  <div class="swiper">
    <!-- <van-swipe :autoplay="3000"> -->
      <!-- <van-swipe-item v-for="(item, index) in list" :key="index"> -->
        <!-- <img v-if="type==''"
          class="img"
          v-lazy="item.substr(0, 1) == '/' ? $IMG_PATH + item : item"
        /> -->
         <!-- <img v-else-if="type=='banner'"
          class="img"
          v-lazy="$IMG_PATH + item "
        /> -->
        <!-- local 本地 -->
          <!-- <img v-else
          class="img"
          v-lazy="item"
        /> -->
      <!-- </van-swipe-item> -->
    <!-- </van-swipe> -->
    <van-swipe :autoplay="3000">
      <van-swipe-item v-for="(item, index) in list" :key="index">
        <img v-if="type==''"
          class="img"
          v-lazy="item.substr(0, 1) == '/' ? item : item"
        />
         <img v-else-if="type=='banner'"
          class="img"
          v-lazy="item "
        />
        <!-- local 本地 -->
          <img v-else
          class="img"
          v-lazy="item"
        />
      </van-swipe-item>
    </van-swipe>
  </div>
</template>

<script>
export default {
  props: {
    list: Array,
    type: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
    };
  },
  created() {
  },
};
</script>

<style scoped lang="less">
.swiper {
  .img {
    display: block;
    width: 100%;
  }
}
</style>